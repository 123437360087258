import { Button, Card, Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useGetServiceForm } from '#/api/servicesQueries';
import useLocalStorage from '#/hooks/useLocalStorage';
import DocumentHistory from '#/components/shared/DocumentHistory';
import useLocales from '#/hooks/useLocales';
import useAccountSwitch from '#/lib/hooks/useAccountSwitch';

export default function SDocumentHistory() {
  const { translate } = useLocales();
  const [guestOn] = useLocalStorage('guest_on', null);
  const { currentAccount } = useAccountSwitch();
  const { state } = useLocation();
  const { data: service } = useGetServiceForm(
    state?.historyAPI,
    !!state?.historyAPI,
    guestOn
  );

  return (
    <Card
      sx={{
        width: '1240px',
        margin: '0 auto',
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '& > button': {
          marginTop: 2,
        },
      }}
    >
      {service?.document_history?.length > 0 ? (
        <Stack spacing={2}>
          <Typography variant="subtitle1">
            {String(
              translate('global.trackHistoryTitle', {
                documentType: String(
                  translate(`global.dashCardInfos.${state?.documentType}.title`)
                ),
              })
            )}
          </Typography>
          <DocumentHistory
            document_history={service?.document_history}
            documentType={state?.documentType}
          />
        </Stack>
      ) : (
        <Stack alignItems="center">
          <Typography variant="subtitle1">
            {String(
              translate('global.dashCardInfos.guest.noDocumentUploaded', {
                hostName: `${currentAccount?.first_name} ${currentAccount?.last_name}`,
                documentType: translate(
                  `global.signedDoc.placeholder.doctype.${state?.documentType}`
                ),
              })
            )}
          </Typography>
        </Stack>
      )}
      <Stack
        spacing={2}
        justifyContent="center"
        direction="row"
        sx={{ marginTop: 2 }}
      >
        <Button
          onClick={() => {
            window.history.back();
          }}
          sx={{ width: 'fit-content' }}
        >
          <Typography>{String(translate('global.back'))}</Typography>
        </Button>
      </Stack>
    </Card>
  );
}
